import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import { currencyFormat } from '../../Product/common/components'
import { Link } from '@material-ui/core'

const Terms = (props) => {
    const auctionData = props.auctionData
    return (
        <div className="date-time-container">
            <div className="dates-time-wrapper">
                <p>
                    <strong>
                        Please make sure you have read the terms of this auction before bidding.
                    </strong>
                </p>
                <p>
                    <strong>Bidding Starts:</strong>
                    {moment(auctionData.date_added)
                        .tz(moment.tz.guess())
                        .format('MMM Do YYYY, h:mm a z')}
                </p>
                <p>
                    <strong>Bidding Ends:</strong>
                    {moment(auctionData.date_closed)
                        .tz(moment.tz.guess())
                        .format('MMM Do YYYY, h:mm a z')}
                </p>

                <p>
                    <strong>Location:</strong>
                    <Link
                        id="aucaddress"
                        itemscope=""
                        itemtype="http://schema.org/PostalAddress"
                        target="_blank"
                        href="https://maps.app.goo.gl/vPDDANoJrWUsWMwq5"
                    >
                        {auctionData.city},{auctionData.state},{auctionData.zipcode}
                    </Link>
                </p>
                <p>
                    <strong>Extended Bidding:</strong> If any bid is placed within the last 120
                    seconds of the lot closing, the lot will automatically be extended by an
                    additional 120 seconds.
                </p>
                <p>
                    <strong>Preview Times:</strong> No Preview for this Auction
                </p>
                <p>
                    <strong>Buyer&apos;s Premium and Fees:</strong> There will be{' '}
                    {auctionData.la_seller_id}% Buyers Premium and{' '}
                    {currencyFormat(parseFloat(auctionData.lot_fee).toFixed(2))} lot fee assessed in
                    this auction
                </p>
                <p>
                    <strong>
                        Returns must be received by{' '}
                        {moment(auctionData.date_closed)
                            .tz(moment.tz.guess())
                            .add(8, 'days')
                            .format('MMM Do YYYY, h:mm a z')}
                    </strong>
                </p>
                <p>
                    <strong>Auction Rules:</strong>
                </p>
                <p>
                    This is an ONSITE auction. All winnin bidders must remove items purchased within
                    the stated load out times. Items that are not removed from the facility, within
                    the loadout time range, will be considered forfeited. There are NO REFUNDS on
                    forfeited items. Winning bidders will be responsible for the removal of items
                    purchased and are required to bring their own help tools and transportation to
                    remove items purchased. All merchandise is dold &#34;ASIS&#34;. We do try our
                    absolute best to perform a basic functionality test on each item to prevent any
                    type of misrepresentation. There are no guarantees that any parts or accessories
                    are included with an item unless they are photographed in the auction.
                </p>
                <p>
                    <strong>Returns Policy</strong>
                </p>
                <p>See auction for return specifics</p>
                <p>
                    <strong>Location</strong>
                </p>
                <p>LOCAL, PICK-UP ONLY. Shipping is unavailable for this auction.</p>
                <p>
                    <strong>Buyers Premium and Fees:</strong> There will be{' '}
                    {auctionData.la_seller_id}% Buyers Premium and{' '}
                    {currencyFormat(parseFloat(auctionData.lot_fee).toFixed(2))} lot fee assessed in
                    this auction. The Buyer Premium will be added to your final invoice.
                </p>
                <p>
                    <strong>Sales Tax:</strong>
                </p>
                <p>
                    Bidder will be subject to pay{' '}
                    {global.storeConfigration?.sales_tax?.value !== undefined
                        ? global.storeConfigration?.sales_tax?.value
                        : 8.5}
                    % for Sales Tax which automatically be added to your final invoice. Sales Tax
                    applies to both the item purchase. price and Buyer’s Premium.
                </p>
                <p>
                    <strong>Payment Collected:</strong>
                </p>
                <p>
                    Credit/Debit cards on file will automatically be charged within 4 hours after
                    the auction close time.
                </p>
                <p>
                    <strong>Payment Methods:</strong>
                </p>
                <p>Credit/Debit Card, Cash</p>
                <p>
                    <strong>Non-Payment:</strong>
                </p>
                <p>
                    If payment for items is not received within the load out time bidders account
                    will be blacklisted. A 25% fee on unpaid items will be assessed if the bidder
                    chooses to reinstate the account. Any future accounts created by a blacklisted
                    bidder will be automatically removed.
                </p>
            </div>
        </div>
    )
}

export default Terms
