import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Favourite from '../Favourite_copy'
import { Image } from 'react-shimmer'
import './cards.css'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import moment from 'moment'
import { getTimeInterval } from '../../utils/commonFunctions'
import { useHistory } from 'react-router-dom'
import FavouriteCheckbox from '../../Product/components/atoms/FavoriteCheckbox'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import AuthContext from '../../Product/context/auth/authContext'
import Timer from '../../Product/common/timer'
import Bidding from '../../Product/components/molecules/Bidding/BiddingItem'
import Facebook from '../../utils/CommonFunctionality/Share/Facebook'
import Whatsapp from '../../utils/CommonFunctionality/Share/Whatsapp'
import Twitter from '../../utils/CommonFunctionality/Share/Twitter'
import Email from '../../utils/CommonFunctionality/Share/Email'
import Pinterest from '../../utils/CommonFunctionality/Share/Pinterest'
import Copy from '../../utils/CommonFunctionality/Share/Copy'
import Biddingforwardandreverse from '../../Product/components/molecules/Bidding/biddingforwardandreverse'
import {
    currencyFormat,
    dateFormatFront,
    handleRedirectInternal,
    sellerNameFormat,
} from '../../Product/common/components'
import { Button, Tooltip, Popover } from '@material-ui/core'
import AddToCalendar from '../../Product/components/molecules/AddToCalendar'
import CartContext from '../../Product/context/cart/cartContext'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { allCondition } from '../../utils'
import axios from 'axios'
import userContext from '../../Product/context/user/userContext'
import CurrencyConverterFormat from '../../utils/CommonFunctionality/CurrencyConverter/CurrencyConverterFormat'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import productContext from '../../Product/context/product/productContext'
import alertContext from '../../Product/context/alert/alertContext'
import Qtyinputcard from '../../Product/common/Qtyinputcard'
import { Rating } from '@material-ui/lab'
import buyerContext from '../../Product/context/buyer/buyerContext'

const LotProduct = ({
    noTimer,
    noBuyNow,
    noBidButton,
    data,
    handleClick,
    type,
    link,
    timerTheme,
    cardTheme,
    additionalCardTheme,
    bidBtnLabel,
    getAllProducts,
    lotImages,
    auction_and_buynow,
    lotBidding,
    currencyType,
    fromSearch,
    className,
    bidStatus,
    showCondition,
    showLocation,
    timerIcon,
    auctionTimer,
    viewProduct,
    setViewProduct,
    lotData,
    lotDetails,
    productViewLink,
    showEstimate,
    audio,
    removePopup,
    need_end_date,
    next_week_date,
    is_registered,
    // setAlert,
    private_auction,
    isMarineTheme,
    fromComponent,
    auction_city,
    hideQuickBid,
}) => {
    const history = useHistory()
    const addDefaultSrc = (e) => {
        e.target.src = `${global?.storeDetails?.logoValue}`
        e.target.className = `gridImage default`
    }
    const isTab = useMediaQuery({
        query: '(max-width: 768px)',
    })
    const authContext = useContext(AuthContext)
    const [showTooltip, setShowTooltip] = useState(false)
    const cartContext = useContext(CartContext)
    const [selectedProduct, setSelectedProduct] = useState({})
    const { t } = useTranslation()
    const {
        addToCart,
        getUserCart,
        removeFromCart,
        responseStatus,
        buyer_cartitems,
        clearResponse,
    } = cartContext
    const { setAlert } = useContext(alertContext)
    const { productLocalState } = useContext(productContext)
    let { bidConfirm } = useContext(buyerContext)
    const { countryValues } = useContext(userContext)

    const { isAuthenticated, user } = authContext
    const [active, setActive] = useState(false)

    const [reload, setReload] = useState(false)

    const [bidText, setBidText] = useState('Bid Now')

    useEffect(() => {
        if (buyer_cartitems.results) {
            if (data.id) {
                let position = _.findIndex(buyer_cartitems.results, {
                    project_id: data.id,
                })
                position === -1 ? setActive(false) : setActive(true)
            }
        }
    }, [buyer_cartitems.results, data.id])

    const result = Math.round(
        (currencyFormat(parseFloat(data.wprice).toFixed(2), '%') /
            currencyFormat(parseFloat(data.mprice).toFixed(2), '%')) *
            100,
    )

    useEffect(() => {
        const currencySplit = Math.round(data.wprice / data.mprice) * 100
        return currencySplit
    }, [data.wprice])

    const handleCart = async (id) => {
        if (global?.storeConfigration?.guest_user_login?.value) {
            if (active) {
                removeFromCart({ id })
            } else
                addToCart({
                    id: data.id,
                    qty: data.qty,
                    manufacture_date:
                        data.custom_field_9 && data.custom_field_9.includes('}')
                            ? JSON.parse(data.custom_field_9)?.inventory_date
                            : '',
                })
        } else {
            if (!user?.card_paymentid) {
                setAlert('Add a card to buy!', 'error')
                return handleRedirectInternal(history, 'dashboard/cards')
            }
            if (active) {
                removeFromCart({ id })
            } else
                addToCart({
                    id: data.id,
                    qty: data.qty,
                    manufacture_date:
                        data.custom_field_9 && data.custom_field_9.includes('}')
                            ? JSON.parse(data.custom_field_9)?.inventory_date
                            : '',
                })
        }
    }

    const categoryTypes = {}
    global.allCategories.map((catgeory) => (categoryTypes[catgeory.id] = catgeory))

    // useEffect(() => {
    //     if (responseStatus) {
    //         if (responseStatus.from === 'addToCart') {
    //             if (responseStatus.status === 'success') {
    //                 console.log('++++++++++++')
    //                 getUserCart()
    //                 clearResponse()
    //             }
    //         } else if (responseStatus.from === 'removeFromCart') {
    //             if (responseStatus.status === 'success') {
    //                 getUserCart()
    //                 clearResponse()
    //             }
    //         }
    //     }
    // }, [responseStatus])

    useEffect(() => {
        if (data?.extra_fields?.includes('}')) {
            let parse = JSON.parse(data.extra_fields)
            Object.keys(parse).map((val) => {
                data[val] = parse[val]
            })
        }
    }, [data?.extra_fields])
    const getBidText = (data) => {
        if (
            isAuthenticated &&
            (data.latestbid_user != null || data.highest_bidder_user_id != null) &&
            user?.id != undefined
        ) {
            if (data.auction) {
                if (
                    (data.latestbid_user == user?.id || data.highest_bidder_user_id == user?.id) &&
                    data.wprice >= data.rprice
                ) {
                    if (data.market_status == 'open') {
                        setBidText('Winning')
                    } else if (data.market_status == 'sold') {
                        setBidText('Won')
                    }
                } else if (
                    (data.latestbid_user != user?.id || data.highest_bidder_user_id != user?.id) &&
                    (data.currentUserBidAmount || data.bid_or_not || data.cbidnot || data.mybid)
                ) {
                    if (data.market_status == 'open') {
                        setBidText('Outbid')
                    } else if (data.market_status == 'sold') {
                        setBidText('Lost')
                    }
                } else {
                    setBidText('Bid Now')
                }
            }
        } else {
            setBidText('Bid Now')
        }
    }

    useEffect(() => {
        getBidText(data)
    }, [data.wprice, user?.id, isAuthenticated])

    const [countryList, setCountryList] = useState([])

    const isArtTheme = document.querySelector('body').classList.contains('artTheme')
    const isHyperMicroTheme = document.querySelector('body').classList.contains('hyperMicro')

    const getLocationData = () => {
        var body = {
            countryCode: 38,
        }

        return axios
            .post(`${process.env.REACT_APP_URL}fetchLocation`, body)
            .then(function (response) {
                if (response.data.success) {
                    setCountryList([
                        response.data.country.find((value) => parseInt(value.location_id) === 224),
                        ...response.data.country.filter(
                            (value) => parseInt(value.location_id) !== 224,
                        ),
                    ])
                }
            })
            .catch(function (error) {
                // console.log(error)
            })
    }

    useEffect(() => {
        // getLocationData()
        getBidText(data)
    }, [data])

    // share icons funa

    const [shareAnchorEl, setShareAnchorEl] = useState(null)

    const handleShareClick = (event) => {
        setShareAnchorEl(event.currentTarget)
    }

    const handleShareClose = () => {
        setShareAnchorEl(null)
    }

    const openshare = Boolean(shareAnchorEl)
    const id = open ? 'simple-popover' : undefined

    const handleProductClick = (URL) => {
        console.warn('awrn')
        history.push({
            pathname: URL,
        })
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    const hanlderedirection = (id) => {
        if (parseInt(is_registered) == 0 && parseInt(private_auction) == 1) {
            setAlert('Please register auction and continue!', 'error')
        } else {
            history.push({
                pathname: `/productView/${id}`,
                state: { lotid: lotDetails },
            })
        }
    }

    const bidsubmit = (data) => {
        bidConfirm({
            auction_io: 1,
            bid_increment: data.incrementamt,
            email: user?.email,
            first_name: user?.first_name,
            hard_bid: '1',
            id: data.id,
            last_name: user?.last_name,
            wsprice: data.next_bid,
        })
    }

    return (
        <div
            className={`card grid-card gallery ${className} 
            ${bidText === 'Winning' && 'winningcard'} 
            ${bidText === 'Outbid' && 'losingcard'} `}
        >
            {!noBidButton && link && fromComponent != 'home' && (
                <>
                    {data.id !== undefined ? (
                        <></>
                    ) : (
                        type !== 'buynow' && (
                            <Link
                                className={`${'pink-btn pbtn-sm'} ${
                                    isAuthenticated &&
                                    data.auction != 0 &&
                                    bidText == 'Winning' &&
                                    'winBtnWrpr'
                                } ${
                                    isAuthenticated &&
                                    data.auction != 0 &&
                                    bidText == 'Outbid' &&
                                    'outbidBtnWrpr'
                                }`}
                                to={link}
                                params={{ product_id: data.id }}
                            >
                                {window.location.pathname.includes('search') ||
                                type === 'Auction' ||
                                data.auction
                                    ? type === 'buynow'
                                        ? isAuthenticated
                                            ? 'Buy Now'
                                            : 'Login To Buy Now'
                                        : isAuthenticated
                                        ? bidText
                                        : 'Login To Bid'
                                    : isAuthenticated
                                    ? 'Buy Now'
                                    : 'Login To Buy Now'}{' '}
                                <i className="fa fa-long-arrow-alt-right"></i>
                            </Link>
                        )
                    )}
                </>
            )}

            <div className="grid-img-wrap">
                {auction_city ? (
                    <div className="grid-loc">
                        <img src="/lots-images/loc.svg" alt="" />
                        {auction_city}, TX
                    </div>
                ) : (
                    ''
                )}

                {isAuthenticated &&
                    (bidText == 'Winning' ||
                        bidText == 'Won' ||
                        bidText == 'Outbid' ||
                        bidText == 'Lost') && (
                        <div
                            className={`${isAuthenticated && 'gridItemAct bid-now-wrap'} ${
                                isAuthenticated &&
                                (bidText == 'Winning' || bidText == 'Won') &&
                                'winBtnWrpr'
                            } ${
                                isAuthenticated &&
                                (bidText == 'Outbid' || bidText == 'Lost') &&
                                'outbidBtnWrpr'
                            }`}
                        >
                            <PrimaryButton
                                disabled={data.market_status == 'sold'}
                                name={data.id}
                                label={bidText}
                                btnColor={data.latestbid_user == user?.id && 'success'}
                            />
                        </div>
                    )}

                {global?.storeConfigration?.sold_status?.value == '1' ? (
                    <></>
                ) : (
                    <>
                        {data.market_status === 'sold' && (
                            <div className="soldWrapper">
                                <p>SOLD</p>
                            </div>
                        )}
                        {data.market_status === 'closed' && (
                            <div className="soldWrapper">
                                <p>UNSOLD</p>
                            </div>
                        )}
                    </>
                )}

                <LazyLoadImage
                    src={`${
                        data.content_head1 === '0' || data.store_id === 0
                            ? process.env.REACT_APP_BASE_URL + 'uploads/product/'
                            : process.env.REACT_APP_PRODUCT_IMAGE_URL
                    }${global.storeConfigration?.image_compression?.value == 1 ? 'thumb_' : ''}${
                        (lotImages?.length && lotImages[0]?.file_name) ||
                        (data?.lotImages?.length && data?.lotImages[0]?.file_name) ||
                        data.file_name ||
                        data.avatar_vc
                    }`}
                    effect="blur"
                    placeholderSrc="/assets/svg/imageLoading.svg"
                    height="100%"
                    width="100%"
                    className="gridImage"
                    //onClick={() => handleClick(data.id)}
                    onError={addDefaultSrc}
                    alt={data.title}
                    onClick={() =>
                        productViewLink
                            ? handleProductClick(productViewLink)
                            : global.storeDetails?.theme === 9
                            ? hanlderedirection(data.id)
                            : cardTheme === 'carAuction'
                            ? history.push({
                                  pathname: `/productView/${data.id}`,
                                  state: { lotid: lotDetails },
                              })
                            : handleClick(data.id)
                    }
                />
                <FavouriteCheckbox
                    watchlisted={data.watchlistid || data.watchlisted || data.isFav}
                    project_id={data.id}
                    from="card"
                    getAllProducts={getAllProducts}
                    viewProduct={viewProduct}
                    setViewProduct={setViewProduct}
                    theme={cardTheme}
                />

                {global.pluginConfiguration?.most_viewed?.enable == 1 && data.viewers ? (
                    <p className="viewsCountValue">Viewers: {data.viewers}</p>
                ) : null}
            </div>
            <div className="card-body">
                <>
                    {data.title !== undefined && (
                        <>
                            {global?.storeConfigration?.lot_sequence_order?.value == 1 ? (
                                <p className="gpAddnInfo">
                                    Lot #: <span>{data?.lot_number || '-'}</span>
                                </p>
                            ) : (
                                ''
                            )}
                            <h5
                                className="name-txt cursorDecoy"
                                onClick={() =>
                                    cardTheme === 'carAuction'
                                        ? history.push({
                                              pathname: `/productView/${data.id}`,
                                              state: { lotid: lotDetails },
                                          })
                                        : productViewLink
                                        ? history.push({
                                              pathname: productViewLink,
                                          })
                                        : handleClick(data.id)
                                }
                                dangerouslySetInnerHTML={{
                                    __html:
                                        cardTheme == 'auctioneer' &&
                                        data.category_id !=
                                            global?.storeConfigration?.similar_category?.value
                                            ? `${
                                                  data.custom_field_9 &&
                                                  data.custom_field_9.includes('}')
                                                      ? JSON.parse(data.custom_field_9)?.game_theme
                                                      : ''
                                              } ${data.subcategory
                                                  ?.split(',')
                                                  ?.map(
                                                      (val) =>
                                                          categoryTypes[val]?.description ?? '',
                                                  )
                                                  ?.join(', ')}`
                                            : global.storeDetails.theme == 19
                                            ? `${
                                                  data.title.length > 25
                                                      ? data.title.slice(0, 25) + '...'
                                                      : data.title
                                              } MSRP ${currencyFormat(
                                                  parseFloat(data.mprice).toFixed(2),
                                                  currencyType,
                                              )}`
                                            : data.title,
                                }}
                            />

                            <div className="ratingLiqThree d-flex justify-content-between">
                                {/* Condition :{' '} */}
                                <div className="d-flex justify-content-between w-100 flex-wrap">
                                    <div className="d-flex justify-content-start align-items-start w-100 flex-wrap loaCategory">
                                        {console.log(data.auctionnotes, '=>auctionnotes')}
                                        {data.auctionnotes.length
                                            ? data.auctionnotes != (null || undefined) &&
                                              data.auctionnotes?.split(',')?.map((val, index) => (
                                                  <div
                                                      className="conditionLiq loa-condition"
                                                      key={index}
                                                  >
                                                      <p className="m-0 cursorDecoy">{val}</p>
                                                  </div>
                                              ))
                                            : null}
                                    </div>

                                    <div className="glot-id">
                                        {' '}
                                        {fromSearch ||
                                        global.storeConfigration?.own_seller?.value == '1' ? (
                                            <h6>
                                                {global.storeDetails.theme == 19 ? (
                                                    <>Lot #{data.lot_number}</>
                                                ) : (
                                                    <>Lot Id# {data.category_id}</>
                                                )}
                                            </h6>
                                        ) : (
                                            <h6>Auction Id# {data.lotof}</h6>
                                        )}
                                    </div>
                                </div>
                                {/* <Rating
                                    name="read-only"
                                    value={
                                        global.storeDetails.theme == 19
                                            ? data.auctionnotes
                                                  .trim()
                                                  .toLowerCase()
                                                  .includes('rating 5')
                                                ? 5
                                                : data.auctionnotes
                                                      .trim()
                                                      .toLowerCase()
                                                      .includes('rating 4')
                                                ? 4
                                                : data.auctionnotes
                                                      .trim()
                                                      .toLowerCase()
                                                      .includes('rating 3')
                                                ? 3
                                                : data.auctionnotes
                                                      .trim()
                                                      .toLowerCase()
                                                      .includes('rating 2')
                                                ? 2
                                                : 1
                                            : 5
                                    }
                                    precision={0.5}
                                    readOnly
                                /> */}
                            </div>
                            {data?.conditionunique &&
                            data?.conditionunique &&
                            global.storeDetails.theme !== 19 ? (
                                <div className="conditionLiq loa-condition">
                                    <p className="m-0 cursorDecoy">{data?.conditionunique}</p>
                                </div>
                            ) : null}
                            {cardTheme == 'auctioneer' && (
                                <>
                                    {global.storeConfigration?.disable_hosted_by?.value != 1 ? (
                                        <h5 className="ref-text">
                                            {data.title} Refurbished{' '}
                                            {data.subcategory
                                                ?.split(',')
                                                ?.map(
                                                    (val) => categoryTypes[val]?.description ?? '',
                                                )
                                                ?.join(', ')}{' '}
                                            Machine
                                        </h5>
                                    ) : null}
                                </>
                            )}
                            {showEstimate === true &&
                                (parseInt(data.lowest) > 0 || parseInt(data.highest) > 0) && (
                                    <p>
                                        Est:{' '}
                                        <span>
                                            {currencyFormat(data.lowest)} -{' '}
                                            {currencyFormat(data.highest)}
                                        </span>
                                    </p>
                                )}
                            {cardTheme !== 'liquidationTwo' && (
                                <>
                                    {global.storeConfigration?.enable_city?.value == 1 &&
                                    data.product_city ? (
                                        <h5 className="hostedBy">{data.product_city}</h5>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            )}
                            {cardTheme === 'liquidationTwo' &&
                            (global.storeConfigration?.condition_field?.value == 1 ||
                                global.storeConfigration?.damaged_field?.value == 1 ||
                                global.storeConfigration?.functional_field?.value == 1 ||
                                global.storeConfigration?.missing_parts_field?.value == 1) ? (
                                <div className="pdtStsInfo">
                                    <ul>
                                        <li>
                                            <label>Condition:</label>
                                            <Tooltip
                                                title={data.condition_field || '-'}
                                                arrow
                                                placement="top"
                                            >
                                                <p
                                                    className={`m-0 cursorDecoy ${
                                                        data.condition_field?.includes(
                                                            'Used - Like New',
                                                        )
                                                            ? 'text-primary'
                                                            : data.condition_field?.includes('New')
                                                            ? 'text-success'
                                                            : 'text-danger'
                                                    }`}
                                                >
                                                    {data.condition_field || '-'}
                                                </p>
                                            </Tooltip>
                                        </li>

                                        <li>
                                            <label>Functional:</label>
                                            <p
                                                className={`m-0 ${
                                                    data.functional_field?.includes('Yes')
                                                        ? 'text-success'
                                                        : 'text-danger'
                                                }`}
                                            >
                                                {data.functional_field || '-'}
                                            </p>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <label>Damaged:</label>
                                            <p
                                                className={`m-0 ${
                                                    data.damaged_field?.includes('No')
                                                        ? 'text-success'
                                                        : 'text-danger'
                                                }`}
                                            >
                                                {data.damaged_field || '-'}
                                            </p>
                                        </li>

                                        <li>
                                            <label>Missing Parts:</label>
                                            <p
                                                className={`m-0 ${
                                                    data.missing_parts_field?.includes('Yes')
                                                        ? 'text-danger'
                                                        : 'text-success'
                                                }`}
                                            >
                                                {data.missing_parts_field || '-'}
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            ) : null}
                            {showCondition ? (
                                <p className="gpAddnInfo">
                                    Condition:{' '}
                                    {cardTheme == 'auctioneer' ? (
                                        <span
                                            className={`${
                                                data.auctionnotes
                                                    ?.trim()
                                                    ?.toLowerCase()
                                                    ?.includes('new')
                                                    ? 'text-success'
                                                    : data.auctionnotes
                                                          ?.trim()
                                                          ?.toLowerCase()
                                                          ?.includes('open box')
                                                    ? 'text-primary'
                                                    : data.auctionnotes
                                                          ?.trim()
                                                          ?.toLowerCase()
                                                          ?.includes('damage')
                                                    ? 'text-danger'
                                                    : ''
                                            }`}
                                        >
                                            {data?.auctionnotes}
                                        </span>
                                    ) : (
                                        <span>
                                            {data?.conditionunique
                                                ? allCondition
                                                      .filter(
                                                          (condition) =>
                                                              condition.id == data.conditionunique,
                                                      )
                                                      .map((d) => d.description)
                                                : '--'}
                                        </span>
                                    )}
                                </p>
                            ) : (
                                ''
                            )}
                            {/* Write Optimized code for this and uncomment */}
                            {/* {showLocation ? (
                                    <p className="gpAddnInfo">
                                        Location:{' '}
                                        <span>
                                            {data?.location
                                                ? countryList
                                                      .filter(
                                                          (country) =>
                                                              country.location_id == data.location,
                                                      )
                                                      .map((d) => d.name)
                                                : '--'}
                                        </span>
                                    </p>
                                ) : (
                                    ''
                                )} */}
                        </>
                    )}
                    {cardTheme == 'HeavyEquipment' ? (
                        <div className="gaInfo">
                            {fromSearch ? (
                                <h6>Lot Id: {data.category_id}</h6>
                            ) : (
                                <h6>Auction Id: {data.id}</h6>
                            )}
                            {(data.buynow == 0 && data.auction == 1) ||
                            (data.buynow == 1 && data.auction == 1) ? (
                                <>
                                    <p>
                                        <span>Start Date:</span>
                                        {moment(data.date_added)
                                            .tz('America/New_York')
                                            .format('MMM Do YYYY, h:mm a')}
                                    </p>
                                    <p>
                                        <span>End Date:</span>
                                        {moment(data.date_closed)
                                            .tz('America/New_York')
                                            .format('MMM Do YYYY, h:mm a')}
                                    </p>
                                </>
                            ) : (
                                ''
                            )}
                            <p
                                className="gaDesc"
                                dangerouslySetInnerHTML={{
                                    __html: data.description,
                                }}
                            ></p>
                        </div>
                    ) : cardTheme == 'carAuction' ? (
                        <div className="autoGridInfo mb-3">
                            {type !== 'buynow' && (
                                <h6 className="crncyValue">
                                    <span className="material-icons-outlined">monetization_on</span>
                                    {data.auction &&
                                        data.wprice &&
                                        currencyFormat(
                                            parseFloat(data.wprice).toFixed(2),
                                            currencyType,
                                        )}
                                    <CurrencyConverterFormat amount={data.wprice} />
                                </h6>
                            )}
                            {data.buynow == 1 && type == 'buynow' && (
                                <h6 className="crncyValue">
                                    <span className="material-icons-outlined">monetization_on</span>
                                    {data.buynow &&
                                        data.bprice &&
                                        currencyFormat(
                                            parseFloat(data.bprice).toFixed(2),
                                            currencyType,
                                        )}
                                    <CurrencyConverterFormat amount={data.bprice} />
                                </h6>
                            )}

                            {!noTimer && data.market_status === 'open' && data.auction ? (
                                <div className="timrVlue">
                                    <span className="material-icons-outlined">watch_later</span>
                                    <Timer
                                        date_added={data.date_added}
                                        date_closed={data.date_closed}
                                        withText={1}
                                        endText={'Time left' + ': '}
                                        startText={'Starts in' + ': '}
                                        theme={timerTheme}
                                        audio={audio}
                                    />
                                </div>
                            ) : (
                                <>
                                    {data.buynow === 0 ? (
                                        <span className="closedText">Auction Closed</span>
                                    ) : (
                                        ''
                                    )}
                                </>
                            )}
                        </div>
                    ) : (
                        <>
                            {cardTheme === 'liquidationTwo' ? (
                                <>
                                    {data.retailer_link && data.mprice ? (
                                        <h6 className="galleryPrice rtlrPrice">
                                            <span className="prcLabel">
                                                Retail Price:{' '}
                                                <a
                                                    href={data.retailer_link}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    (Retailer)
                                                </a>
                                            </span>
                                            &nbsp;
                                            <span className="prcTxtVal">
                                                {currencyFormat(
                                                    parseFloat(data.mprice || 0).toFixed(2),
                                                    currencyType,
                                                )}
                                                <CurrencyConverterFormat amount={data.mprice} />
                                            </span>
                                            {/* <span className="prcTxtVal">
                                                </span> */}
                                        </h6>
                                    ) : (
                                        <h6 className="galleryPrice rtlrPrice"></h6>
                                    )}
                                </>
                            ) : (
                                <></>
                            )}

                            {type !== 'buynow' && (
                                // parseInt(global.storeConfigration.length) > 0 ?
                                // (!!Number(global.storeConfigration[2].value) ||
                                //     !!Number(global.storeConfigration[3].value)) && (
                                <h6 className="galleryPrice actnPriceVlue nowrap d-flex">
                                    {(data.auction && data.wprice) || fromComponent == 'home' ? (
                                        <>
                                            {data.market_status == 'closed' ? (
                                                <span>UnSold</span>
                                            ) : (
                                                <>
                                                    {global.pluginConfiguration?.reverse_auction
                                                        ?.enable &&
                                                    parseInt(data.content_head5) == 1 ? (
                                                        <span>
                                                            {data.market_status == 'sold'
                                                                ? 'Sold'
                                                                : 'Current'}{' '}
                                                            Bid:{' '}
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            {data.market_status == 'sold'
                                                                ? data.nobids ||
                                                                  fromComponent == 'home'
                                                                    ? 'Sold'
                                                                    : 'Start'
                                                                : data.nobids || data.bid_count
                                                                ? 'Current'
                                                                : 'Current'}{' '}
                                                            Bid:{' '}
                                                        </span>
                                                    )}
                                                    <span className="d-flex align-items-center">
                                                        {fromComponent == 'home' ? (
                                                            <span className="prcTxtVal">
                                                                {/* <div>{result}%</div> */}
                                                                {currencyFormat(
                                                                    parseFloat(
                                                                        data.wprice
                                                                            ? data.wprice
                                                                            : data.bprice,
                                                                    ).toFixed(2),
                                                                    currencyType,
                                                                )}
                                                                <CurrencyConverterFormat
                                                                    amount={data.wprice}
                                                                />
                                                            </span>
                                                        ) : (
                                                            <span className="prcTxtVal">
                                                                {/* <div>{result}%</div> */}
                                                                {currencyFormat(
                                                                    parseFloat(data.wprice).toFixed(
                                                                        2,
                                                                    ),
                                                                    currencyType,
                                                                )}
                                                                <CurrencyConverterFormat
                                                                    amount={data.wprice}
                                                                />
                                                            </span>
                                                        )}
                                                        {/* <span className="prcTxtVal">
                                                            </span> */}
                                                        {cardTheme === 'liquidationTwo' && (
                                                            <>
                                                                {global?.storeConfigration
                                                                    ?.view_bid_history
                                                                    ?.value ? null : (
                                                                    <>
                                                                        &nbsp; |{' '}
                                                                        {data.nobids ||
                                                                            data.bid_count ||
                                                                            0}{' '}
                                                                        Bids
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </span>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {' '}
                                            {data.market_status == 'closed' ? (
                                                <span>UnSold</span>
                                            ) : (
                                                <>
                                                    {data.auction ? (
                                                        <span className="crncyNtAvlblVlue">
                                                            <span>
                                                                {data.market_status == 'sold'
                                                                    ? 'Sold'
                                                                    : parseInt(data.wprice) ===
                                                                      parseInt(data.sprice)
                                                                    ? 'Current'
                                                                    : 'Current'}
                                                                Bid:
                                                            </span>
                                                            {data.buynow && data.bprice ? (
                                                                <span className="prcTxtVal">
                                                                    {currencyFormat(
                                                                        parseFloat(
                                                                            data.bprice,
                                                                        ).toFixed(2),
                                                                        currencyType,
                                                                    )}
                                                                    <CurrencyConverterFormat
                                                                        amount={data.bprice}
                                                                    />
                                                                </span>
                                                            ) : (
                                                                <span className="gridNotAvailable">
                                                                    Not Available
                                                                </span>
                                                            )}
                                                        </span>
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </h6>
                            )}
                            {data.next_bid ? (
                                <h6 className="galleryPrice actnPriceVlue nowrap d-flex">
                                    <span>Min Bid: </span>
                                    <span className="d-flex align-items-center">
                                        <span className="prcTxtVal">
                                            {data.next_bid
                                                ? currencyFormat(
                                                      parseFloat(data.next_bid).toFixed(2),
                                                      currencyType,
                                                  )
                                                : currencyFormat(
                                                      parseFloat(data.wprice).toFixed(2),
                                                      currencyType,
                                                  )}
                                        </span>
                                    </span>
                                </h6>
                            ) : (
                                ''
                            )}
                            {data.incrementamt ? (
                                <h6 className="galleryPrice actnPriceVlue nowrap d-flex">
                                    <span>Bid Increment: </span>
                                    <span className="d-flex align-items-center">
                                        <span className="prcTxtVal">
                                            {currencyFormat(
                                                parseFloat(data.incrementamt).toFixed(2),
                                                currencyType,
                                            )}
                                        </span>
                                    </span>
                                </h6>
                            ) : (
                                ''
                            )}
                            <h6 className="galleryPrice actnPriceVlue nowrap d-flex">
                                <span>MSRP: </span>
                                <span className="d-flex align-items-center">
                                    <span className="prcTxtVal">
                                        {currencyFormat(
                                            parseFloat(data.mprice).toFixed(2),
                                            currencyType,
                                        )}
                                    </span>
                                </span>
                            </h6>

                            {global.storeDetails?.theme === 19 && (
                                <>
                                    {(data.market_status === 'open' && data.auction) ||
                                    (data.market_status === 'open' && auction_and_buynow) ? (
                                        <Timer
                                            date_added={data.date_added}
                                            date_closed={data.date_closed}
                                            withText={1}
                                            endText={'Time Remaining' + ': '}
                                            startText={'Starts in' + ': '}
                                            theme={timerTheme}
                                            iconGrid={timerIcon}
                                            audio={audio}
                                        />
                                    ) : (
                                        <>
                                            {data.buynow === 0 ? (
                                                <div className="closedText">Auction Closed</div>
                                            ) : (
                                                <div className="closedText"></div>
                                            )}
                                        </>
                                    )}
                                </>
                            )}

                            {global.storeDetails?.theme === 18 && (
                                <>
                                    <div className="d-flex align-items-center justify-content-between">
                                        {isAuthenticated && (
                                            <span>
                                                {global?.storeConfigration?.view_bid_history
                                                    ?.value ? (
                                                    <>
                                                        &nbsp;
                                                        {data.nobids || data.bid_count || 0} Bids
                                                    </>
                                                ) : null}
                                            </span>
                                        )}
                                        {!noTimer && data.date_closed && (
                                            <div
                                                className={`gcTimer primeTmCrd  ${
                                                    data.buynow === 1 && data.auction === 0
                                                        ? 'noBackDrop'
                                                        : ''
                                                } ${
                                                    isAuthenticated &&
                                                    global.storeDetails?.theme === 18 &&
                                                    'noTimeLeft'
                                                }`}
                                            >
                                                {/*eslint-disable*/}
                                                {(data.market_status === 'open' && data.auction) ||
                                                (data.market_status === 'open' &&
                                                    auction_and_buynow) ? (
                                                    <Timer
                                                        date_added={data.date_added}
                                                        date_closed={data.date_closed}
                                                        withText={1}
                                                        endText={'Time left' + ': '}
                                                        startText={'Starts in' + ': '}
                                                        theme={timerTheme}
                                                        icon={timerIcon}
                                                        audio={audio}
                                                    />
                                                ) : (
                                                    <>
                                                        {data.buynow === 0 ? (
                                                            <span className="closedText">
                                                                Auction Closed
                                                            </span>
                                                        ) : (
                                                            <div className="closedText"></div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                            {global.storeConfigration?.show_condition?.value == 1 &&
                            cardTheme == 'liquidationTwo' ? (
                                <h6 className="galleryPrice condition">
                                    <span>Condition: </span>
                                    <span
                                        className={`prcTxtVal ${
                                            data.auctionnotes
                                                ?.trim()
                                                ?.toLowerCase()
                                                ?.includes('new')
                                                ? 'badge badge-success text-white'
                                                : data.auctionnotes
                                                      ?.trim()
                                                      ?.toLowerCase()
                                                      ?.includes('open box')
                                                ? 'badge badge-primary text-white'
                                                : data.auctionnotes
                                                      ?.trim()
                                                      ?.toLowerCase()
                                                      ?.includes('damage')
                                                ? 'badge badge-danger text-white'
                                                : ''
                                        }`}
                                        title={data.auctionnotes ? data.auctionnotes : '-'}
                                    >
                                        {data.auctionnotes ? data.auctionnotes : '-'}
                                    </span>
                                </h6>
                            ) : null}

                            {global.storeConfigration?.show_warehouse_location?.value == 1 ? (
                                <h6 className="galleryPrice condition">
                                    <span>Location: </span>
                                    <span
                                        className="prcTxtVal"
                                        title={data.location ? data.location : '-'}
                                    >
                                        {data.location ? data.location : '-'}
                                    </span>
                                </h6>
                            ) : null}

                            {global?.storeConfigration?.need_transferable?.value == 1 ? (
                                <h6
                                    className="galleryPrice condition"
                                    style={{ display: 'flex', justifyContent: 'space-between' }}
                                >
                                    <span style={{ display: 'flex' }}>
                                        Transferable:
                                        {data.transferable ? (
                                            <Tooltip
                                                title={
                                                    data.transferable == 'Yes'
                                                        ? 'This item can be transfered to a different warehouse location for pickup.'
                                                        : data.transferable == 'No'
                                                        ? 'This item must be picked up from its original location.'
                                                        : ''
                                                }
                                                arrow
                                                placement="top-start"
                                                open={showTooltip}
                                                onOpen={() => setShowTooltip(true)}
                                                onClose={() => setShowTooltip(false)}
                                                className="tooling"
                                                style={{ marginLeft: '5px' }}
                                            >
                                                <span onClick={() => setShowTooltip(!showTooltip)}>
                                                    i
                                                </span>
                                            </Tooltip>
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                    <span
                                        className="prcTxtVal"
                                        title={data.transferable ? data.transferable : '-'}
                                    >
                                        {data.transferable ? data.transferable : '-'}
                                    </span>
                                </h6>
                            ) : null}

                            {!noBuyNow &&
                                type !== 'auction' &&
                                cardTheme !== 'auctioneer' &&
                                (data.buynow && data.bprice ? (
                                    <h6 className={`${!data.buynow ? 'noPrice' : 'galleryPrice'}`}>
                                        <span>
                                            {data.market_status === 'sold' ? 'Sold' : 'Buy Now'}{' '}
                                            Price:{' '}
                                        </span>
                                        &nbsp;
                                        <span className="prcTxtVal">
                                            {currencyFormat(
                                                parseFloat(data.bprice).toFixed(2),
                                                currencyType,
                                            )}
                                            <CurrencyConverterFormat amount={data.bprice} />
                                        </span>
                                        {/* <span className="prcTxtVal">
                                                </span> */}
                                    </h6>
                                ) : (
                                    <></>
                                ))}

                            {!noBuyNow && type !== 'auction' && cardTheme == 'auctioneer' && (
                                <>
                                    {data.buynow && data.bprice ? (
                                        <div className="d-flex justify-content-between align-items-center mb-4 mt-3">
                                            <div className="price-currency">
                                                <div className="pro-price">
                                                    {currencyFormat(data.bprice)}
                                                </div>
                                                <CurrencyConverterFormat amount={data.bprice} />
                                                <div className="pro-price-label">
                                                    +{' '}
                                                    {data.hasshipping ? 'Shipping' : 'Local Pickup'}
                                                </div>
                                            </div>
                                            <div className="pro-price-label">
                                                {/* <p>2 years warranty</p>
                                                    <p>Add-ons available</p> */}
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            )}
                        </>
                    )}

                    {global?.storeConfigration?.buy_now_with_qty?.value == 1 ? (
                        data.buynow &&
                        data.bprice &&
                        data.category_id != global?.storeConfigration?.similar_category?.value ? (
                            <h6
                                // style={{ minHeight: '23px', height: '100%' }}
                                className={`${!data.buynow ? 'noPrice' : ''}`}
                            >
                                <span>Available Qty: </span>
                                &nbsp;
                                <span className="prcTxtVal">{data.qty}</span>
                            </h6>
                        ) : (
                            <></>
                        )
                    ) : (
                        ''
                    )}

                    {lotBidding ? (
                        lotBidding
                    ) : data.date_closed &&
                      moment(data.date_closed).isValid() &&
                      auctionTimer &&
                      moment(auctionTimer).isValid() &&
                      new Date(data.date_closed).getTime() - new Date(auctionTimer).getTime() < 0 &&
                      !data.buynow ? (
                        <p> </p>
                    ) : data?.user_id == user?.id && data.market_status == 'open' ? (
                        <p className="cntBidOwn" style={{ whiteSpace: 'nowrap' }}>
                            {data.buynow
                                ? 'You cannot buy your own product!'
                                : 'You cannot bid on your own product!'}
                        </p>
                    ) : (
                        <>
                            {cardTheme == 'auctioneer' ||
                            cardTheme == 'liquidationTwo' ||
                            cardTheme == 'carAuction' ? (
                                data.auction &&
                                !window.location.pathname.includes('gallery') &&
                                type !== 'buynow' ? (
                                    <>
                                        <div
                                            className={`gpBidCart ${
                                                cardTheme === 'carAuction' ? 'carAucBid' : ''
                                            }`}
                                        >
                                            <Bidding
                                                theme="multiseller"
                                                data={data}
                                                hard={
                                                    global.storeConfigration?.hard_bid?.value || '1'
                                                }
                                                shortText={bidBtnLabel}
                                                removePopup={removePopup}
                                            />
                                            {cardTheme !== 'carAuction' ? (
                                                <>
                                                    {type == 'buynow' && type != 'Auction'
                                                        ? ''
                                                        : isAuthenticated &&
                                                          cardTheme != 'liquidationTwo' &&
                                                          ((parseInt(data.buynow) == 1 &&
                                                              parseInt(data.auction) == 0) ||
                                                              (parseInt(data.wprice) <=
                                                                  parseInt(data.bprice) &&
                                                                  parseInt(data.buynow) == 1 &&
                                                                  parseInt(data.auction) == 1)) &&
                                                          data.market_status == 'open' && (
                                                              <Button
                                                                  className={`cartBtn ${
                                                                      active ? 'active' : ''
                                                                  }`}
                                                                  onClick={() =>
                                                                      handleCart(data.id)
                                                                  }
                                                                  disabled={data.buynow == 0}
                                                              >
                                                                  <span className="material-icons-outlined">
                                                                      {active
                                                                          ? 'shopping_cart_checkout'
                                                                          : 'add_shopping_cart'}
                                                                  </span>
                                                              </Button>
                                                          )}
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                        {cardTheme == 'liquidationTwo' ? (
                                            <>
                                                {type == 'buynow' && type != 'Auction' ? (
                                                    <div className={`gpBidCart spByNw`}></div>
                                                ) : ((parseInt(data.buynow) == 1 &&
                                                      parseInt(data.auction) == 0) ||
                                                      (parseInt(data.wprice) <=
                                                          parseInt(data.bprice) &&
                                                          parseInt(data.buynow) == 1 &&
                                                          parseInt(data.auction) == 1)) &&
                                                  data.market_status == 'open' ? (
                                                    <div className="gpBidCart spByNw">
                                                        {isAuthenticated ? (
                                                            <PrimaryButton
                                                                btnSize={'small'}
                                                                onClick={() => handleCart(data.id)}
                                                                disabled={data.buynow == 0}
                                                                label={
                                                                    active === true
                                                                        ? 'Added to Cart'
                                                                        : cardTheme !== 'auctioneer'
                                                                        ? `Buy it Now: ${currencyFormat(
                                                                              data.bprice,
                                                                          )}`
                                                                        : 'Add to Cart'
                                                                }
                                                            />
                                                        ) : (
                                                            <PrimaryButton
                                                                btnSize={'small'}
                                                                onClick={() =>
                                                                    handleRedirectInternal(
                                                                        history,
                                                                        'login',
                                                                    )
                                                                }
                                                                disabled={data.buynow == 0}
                                                                label={`Login To Buy: ${currencyFormat(
                                                                    data.bprice,
                                                                )}`}
                                                            />
                                                        )}
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                ) : data.market_status !== 'sold' ? (
                                    <>
                                        {cardTheme === 'liquidationTwo' &&
                                            type == 'buynow' &&
                                            type != 'Auction' && (
                                                <div className="noBidAboveDiv"></div>
                                            )}
                                        {isAuthenticated ||
                                        global?.storeConfigration?.guest_user_login?.value ? (
                                            <PrimaryButton
                                                btnSize={
                                                    cardTheme === 'liquidationTwo' ? 'small' : ''
                                                }
                                                onClick={() => handleCart(data.id)}
                                            >
                                                {active === true
                                                    ? 'Added to Cart'
                                                    : cardTheme !== 'auctioneer'
                                                    ? `Buy it Now: ${currencyFormat(data.bprice)}`
                                                    : 'Add to Cart'}
                                            </PrimaryButton>
                                        ) : (
                                            <PrimaryButton
                                                btnSize={
                                                    cardTheme === 'liquidationTwo' ? 'small' : ''
                                                }
                                                onClick={() =>
                                                    handleRedirectInternal(history, 'login')
                                                }
                                            >
                                                {`Login To Buy: ${currencyFormat(data.bprice)}`}
                                            </PrimaryButton>
                                        )}
                                        {cardTheme === 'liquidationTwo' &&
                                            type == 'buynow' &&
                                            type != 'Auction' && (
                                                <div className="text-center endDteTxt noBidDownDiv"></div>
                                            )}

                                        {/* {!isAuthenticated && (
                                                <>
                                                    {cardTheme === 'liquidationTwo' &&
                                                        type == 'buynow' &&
                                                        type != 'Auction' && (
                                                            <div className="noBidDiv"></div>
                                                        )}
                                                </>
                                            )} */}
                                    </>
                                ) : null
                            ) : cardTheme == 'multiseller' || cardTheme == 'fundRaiser' ? (
                                data.auction && !window.location.pathname.includes('gallery') ? (
                                    data.date_closed &&
                                    (global.pluginConfiguration?.reverse_auction?.enable ? (
                                        <Biddingforwardandreverse
                                            theme="multiseller"
                                            data={data}
                                            hard="1"
                                            shortText={bidBtnLabel}
                                            is_registered={is_registered}
                                            private_auction={private_auction}
                                        />
                                    ) : (
                                        <Bidding
                                            theme="multiseller"
                                            data={data}
                                            hard={global.storeConfigration?.hard_bid?.value || '1'}
                                            shortText={bidBtnLabel}
                                            removePopup={removePopup}
                                        />
                                    ))
                                ) : data.market_status !== 'sold' ? (
                                    <PrimaryButton
                                        onClick={() =>
                                            isAuthenticated
                                                ? productViewLink
                                                    ? history.push({
                                                          pathname: productViewLink,
                                                      })
                                                    : handleClick(data.id)
                                                : handleRedirectInternal(history, 'login')
                                        }
                                    >
                                        {isAuthenticated ? 'Buy Now' : 'Login To Buy'}
                                    </PrimaryButton>
                                ) : null
                            ) : cardTheme == 'HeavyEquipment' ? (
                                <div className="gaCTA">
                                    <PrimaryButton
                                        onClick={() =>
                                            productViewLink
                                                ? history.push({
                                                      pathname: productViewLink,
                                                  })
                                                : handleClick(data.id)
                                        }
                                    >
                                        View Details
                                    </PrimaryButton>
                                    <AddToCalendar data={data} />
                                </div>
                            ) : global?.storeConfigration?.qty_input_slider?.value == 1 &&
                              data.buynow ? (
                                ''
                            ) : new Date(data.date_added) > new Date() &&
                              data.content_head5 == 1 &&
                              global.pluginConfiguration?.reverse_auction?.enable &&
                              !data?.make_an_offer_io ? (
                                <p>Wait until auction starts!</p>
                            ) : (
                                <div className="timer-text mt-2"></div>
                            )}
                        </>
                    )}
                    {global?.storeConfigration?.qty_input_slider?.value == 1 && data.buynow ? (
                        <>
                            <div className="newAdded">
                                <Qtyinputcard
                                    total_qty={data.qty}
                                    qty={'1'}
                                    product_id={data.id}
                                    disabled={active}
                                    isAuthenticated={isAuthenticated}
                                    history={history}
                                />
                                {data.market_status == 'open' && (
                                    <FavouriteCheckbox
                                        watchlisted={
                                            data.watchlistid ||
                                            data.watchlisted ||
                                            data.isFav ||
                                            data.watch
                                        }
                                        project_id={data.id}
                                        getAllProducts={getAllProducts}
                                        data={data}
                                        from={'card'}
                                        viewProduct={viewProduct}
                                        setViewProduct={setViewProduct}
                                    />
                                )}
                            </div>
                        </>
                    ) : (
                        ''
                    )}
                </>
            </div>

            {!noBidButton && link && fromComponent != 'home' && (
                <>
                    {data.id !== undefined ? (
                        type !== 'buynow' && (
                            <>
                                <div className={`${isAuthenticated && 'gridItemAct bid-now-wrap'}`}>
                                    <PrimaryButton
                                        name={data.id}
                                        onClick={() =>
                                            isAuthenticated
                                                ? productViewLink
                                                    ? history.push({
                                                          pathname: productViewLink,
                                                      })
                                                    : handleClick(data.id)
                                                : handleRedirectInternal(history, 'login')
                                        }
                                        label={
                                            window.location.pathname.includes('search') ||
                                            type === 'Auction' ||
                                            data.auction
                                                ? (type === 'buynow' || data.buynow) &&
                                                  (!data.auction ||
                                                      window.location.href.includes('/gallery'))
                                                    ? isAuthenticated
                                                        ? 'Buy Now'
                                                        : 'Login To Buy Now'
                                                    : isAuthenticated
                                                    ? 'Bid Now'
                                                    : 'Login To Bid'
                                                : isAuthenticated
                                                ? 'Buy Now'
                                                : 'Login To Buy Now'
                                        }
                                        btnColor={data.latestbid_user == user?.id && 'success'}
                                    />

                                    {cardTheme !== 'carAuction' &&
                                    global?.storeConfigration?.qty_input_slider?.value != 1 &&
                                    data.market_status == 'open' ? (
                                        <>
                                            {type != 'buynow' && type == 'Auction'
                                                ? ''
                                                : isAuthenticated &&
                                                  parseInt(data.wprice) <=
                                                      parseInt(data.bprice) && (
                                                      <Button
                                                          className={`cartBtn ${
                                                              active ? 'active' : ''
                                                          } `}
                                                          onClick={() => handleCart(data.id)}
                                                          disabled={
                                                              data.buynow == 0 ||
                                                              user?.status == 'suspended'
                                                          }
                                                      >
                                                          <span className="material-icons-outlined">
                                                              {active
                                                                  ? 'shopping_cart_checkout'
                                                                  : 'add_shopping_cart'}
                                                          </span>
                                                      </Button>
                                                  )}
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                {data.auction && isAuthenticated ? (
                                    type === 'buynow' && !data.auction ? null : (
                                        <>
                                            {global.storeConfigration?.hard_bid?.value == '1' &&
                                                (hideQuickBid === undefined || !hideQuickBid) && (
                                                    <div className="lots-bidding-view mt-2">
                                                        <Bidding
                                                            data={data}
                                                            hard="1"
                                                            shortText="Submit Your Bid"
                                                            enableNextBid={true}
                                                            nextbidText={`Quick Bid ${
                                                                data?.next_bid
                                                                    ? currencyFormat(
                                                                          parseFloat(
                                                                              data?.next_bid
                                                                                  ? data?.next_bid
                                                                                  : 0,
                                                                          ).toFixed(2),
                                                                      )
                                                                    : ''
                                                            }`}
                                                            hideBid={true}
                                                        />
                                                    </div>
                                                )}
                                        </>
                                    )
                                ) : null}
                            </>
                        )
                    ) : (
                        <></>
                    )}
                </>
            )}
            {!noBidButton && link && fromComponent != 'home' && (
                <>
                    {data.id !== undefined &&
                    (!data.auction || window.location.href.includes('/gallery')) ? (
                        <div className={`${isAuthenticated && 'gridItemAct'}  grid-bid-btns`}>
                            <PrimaryButton
                                disabled={data.market_status == 'sold'}
                                name={data.id}
                                onClick={() =>
                                    isAuthenticated
                                        ? productViewLink
                                            ? history.push({
                                                  pathname: productViewLink,
                                              })
                                            : handleClick(data.id)
                                        : handleRedirectInternal(history, 'login')
                                }
                                label={
                                    window.location.pathname.includes('search') ||
                                    type === 'Auction' ||
                                    data.auction
                                        ? (type === 'buynow' || data.buynow) &&
                                          (!data.auction ||
                                              window.location.href.includes('/gallery'))
                                            ? isAuthenticated
                                                ? 'Buy Now'
                                                : 'Login To Buy Now'
                                            : isAuthenticated
                                            ? bidText
                                            : 'Login To Bid'
                                        : isAuthenticated
                                        ? 'Buy Now'
                                        : 'Login To Buy Now'
                                }
                                btnColor={data.latestbid_user == user?.id && 'success'}
                            />

                            {cardTheme !== 'carAuction' &&
                            global?.storeConfigration?.qty_input_slider?.value != 1 &&
                            data.market_status == 'open' ? (
                                <>
                                    {type != 'buynow' && type == 'Auction'
                                        ? ''
                                        : isAuthenticated &&
                                          parseInt(data.wprice) <= parseInt(data.bprice) && (
                                              <Button
                                                  className={`cartBtn ${active ? 'active' : ''} `}
                                                  onClick={() => handleCart(data.id)}
                                                  disabled={
                                                      data.buynow == 0 ||
                                                      user?.status == 'suspended'
                                                  }
                                              >
                                                  Add to Cart
                                              </Button>
                                          )}
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </div>
    )
}

export default LotProduct
