import React, { useEffect, useState, useContext, useRef } from 'react'
import './gallery.css'
import { getParameterByName } from '../../../utils/commonFunctions'
import ProductContext from '../../../Product/context/product/productContext'
import AuthContext from '../../../Product/context/auth/authContext'
import NoRecordsFound from '../../../Product/components/atoms/NoRecordsFound'
import SearchProductList from '../../../Component/ProductCard/product'
import Layout from '../Layout'
import { Pagination } from '@material-ui/lab'
import Loaders from '../../../Product/components/molecules/Loaders'
import UserActivity from '../../../utils/CommonFunctionality/UserActivity'
import { qtyHandleBuynow } from '../../../Product/common/socketHandler'
import { socket } from '../../../Product/common/socket'

const GalleryComponent = (props) => {
    // const [cookies, setCookie] = useCookies(['user_session'])

    const productContext = useContext(ProductContext)
    const authContext = useContext(AuthContext)

    const { search_allproducts, getAllSearchProducts, getAllSearchItems } = productContext

    const { isAuthenticated, user } = authContext

    const [isLoading, setLoading] = useState(true)
    const [viewProduct, setViewProduct] = useState([])
    const [listview, setlistview] = useState(false)
    const [totalViewProduct, setTotalViewProduct] = useState(0)

    const [productSearch, setProductSearch] = useState({
        sh_limit: 20,
        page: 1,
        joinsdata: `p.auctionnotes,p.avatar as avatar_vc,p.content_head1,p.store_id,p.retailer_link,p.conditionunique,p.hasshipping,p.user_id,p.category_id${
            user?.id ? ',IFNULL(w.id,0) as watchlisted' : ''
        }`,
        userid: user?.id,
        is_cart: 1,
        order_by: ' order by p.date_closed desc ',
        customwhere: 'p.auction=0 and p.buynow=1 and p.market_status="open"',
        joins: user?.id
            ? `left join watchlists as w on w.project_id=p.id and w.user_id = ${user?.id} and w.is_delete = 0`
            : undefined,
    })

    useEffect(() => {
        // setgalleryPageLoading(true)
        // getAllSearchProducts(productSearch, 'galleryPage')
        getAllSearchItems(productSearch, 'galleryPage')
    }, [productSearch, isAuthenticated])

    useEffect(() => {
        setLoading(true)
        if (search_allproducts.from === 'galleryPage') {
            setViewProduct(search_allproducts.results.length ? search_allproducts.results : [])
            setTotalViewProduct(search_allproducts.total_pagecnt)
        }
        setTimeout(() => {
            setLoading(false)
        }, 1000)
    }, [search_allproducts])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const onHandlePage = (event, value) => {
        setProductSearch({ ...productSearch, page: value })
        window.scrollTo(0, 0)
    }

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)

    const handler = (message, type) => {
        qtyHandleBuynow(message, viewProductRef.current, userRef.current, setViewProduct, type)
    }

    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    useEffect(() => {
        socket.on('real_close_update_buynow', (data) => {
            handler(data, 'real_close_update_buynow')
        })

        return () => {
            socket.off('bidAddtime', (data) => {
                handler(data, 'real_close_update_buynow')
            })
        }
    }, [])

    return (
        <Layout props={props}>
            <UserActivity page="buynow_page" />
            <div className="container mt-5">
                {isLoading ? (
                    <Loaders name="product_grid_view" isLoading={isLoading} loop={4} />
                ) : viewProduct.length > 0 ? (
                    <>
                        <h2 className="buyNowTitle">Buy Now Items</h2>
                        <SearchProductList
                            listview={listview}
                            searchResult={viewProduct}
                            className="liquidationBuyCard"
                            type="buynow"
                            noTimer={true}
                            enableZoomOnHover={true}
                            setViewProduct={setViewProduct}
                        />
                        {totalViewProduct > productSearch.sh_limit ? (
                            <Pagination
                                count={Math.ceil(totalViewProduct / productSearch.sh_limit)}
                                page={productSearch.page}
                                onChange={onHandlePage}
                            />
                        ) : null}
                    </>
                ) : (
                    <NoRecordsFound />
                )}
            </div>
        </Layout>
    )
}

export default GalleryComponent
